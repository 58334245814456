body {
  font-family: Montserrat, apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 14px;
}

.fa-spin {
  animation-duration: 0.8s;
}

button:focus {
  outline: none;
}

input:focus,
input:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a {
  text-decoration: none;
}

@media (min-width: 500px) {
  #main-content {
    max-width: 400px;
    margin: auto;
  }
}

.wrapper {
  padding: 10px 12px;
}

p {
  margin: 0;
  padding: 0;
}

image {
  width: 100%;
  height: 100%;
}

#main-content {
  height: 100vh;
  background: transparent
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(206, 219, 213, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    )
    0% 0% no-repeat padding-box;
}

#home {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 20%;
}

#home .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dropdown .username-display i {
  margin-left: 5px;
}

.dropdown .username-display {
  display: flex;
  align-items: center;
  color: #0c4f2e;
  font-size: 18px;
}

.user-dropdown img {
  width: 10px;
}

.user-dropdown {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid #aac1b6;
}

.username-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0c4f2e;
}

.username-container > i {
  color: #414141;
  color: #0c4f2e;
}

.username-container p {
  margin-right: 5px;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.user-dropdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropdown-container {
  display: relative;
  user-select: none;
}

.dropdown-links {
  position: absolute;
  z-index: 50;
  width: 200px;
  text-align: center;
  right: 35px;
  background-color: white;
  padding: 15px 20px;
  border-radius: 15px;
  transition: transform 0.2s, opacity 0.2s;
  opacity: 1;
  transform: translateY(6px);
}

.dropdown-links.hidden {
  opacity: 0;
  transform: translateY(15px);
}

.dropdown-links > a:first-of-type {
  border-bottom: 1px solid #70707060;
  padding-top: 0;
  padding-bottom: 5px;
}

.dropdown-links > a {
  display: block;
  padding-top: 5px;
  color: #0c4f2e;
  font-weight: 500;
}

.cases-btn-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  padding: 10px;
  max-height: 30px;
  border: 1px solid #0c4f2e;
  cursor: pointer;
  transition: background-color 0.4s;
}

.case-btn.active div {
  background-color: white;
  color: black;
}

.case-btn .num {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  background-color: #0c4f2e;
  transition: background-color 0.4s;
}

.case-btn.active {
  background-color: #0c4f2e;
  color: white;
}

.cards {
  align-items: center;
  margin-top: 20px;
  /* overflow-y: auto; */
  /* height: calc(100% - 90px); */
}

.cards .loader {
  font-size: 18px;
  width: 10%;
  margin: auto;
}

.no-results {
  color: #0c4f2e;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}

.cases-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px 14px;
  margin-bottom: 16px;
  border-radius: 20px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.171);
}

.cases-container .top-section {
  font-size: 12px;
}

.filter-cont {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.react-datepicker-wrapper,
.search-field {
  margin-bottom: 14px;
}

.filter-cont input {
  padding: 8px;
  width: 100%;
  border: 1px solid #0c4f2e5d;
  background: none;
  border-radius: 10px;
}

.filter-cont button:after {
  margin-bottom: 15px;
}

.filter-cont button:after {
  background-color: rgb(189, 0, 0);
}

.case-card {
  /* border: 0.20000000298023224px solid #707070; */
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.11);
}

.case-card .main span {
  color: #0c4f2e;
  font-weight: 600;
}

.case-card .main.details .amount {
  font-size: 11px;
}

.case-card .main.details p {
  font-weight: 500;
  font-size: 13px;
}

.case-card .meta {
  font-size: 11px;
}

.case-card .meta p {
  color: #414141;
}

.case-card .meta span {
  color: #0c4f2e;
}

.case-card .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.input-fields {
  display: flex;
  flex-direction: column;
}

button {
  border-radius: 5px;
  border: none;
}

#chat {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
}

#chat .error {
  margin: auto;
  text-align: center;
}

#chat .error a {
  display: block;
  margin: 16px 0;
}

#chat .loading {
  margin: auto;
}

#chat .action-btns .close-case {
  background-color: rgb(201, 0, 0);
}

#chat .action-btns button {
  font-weight: 500;
  background-color: #0c4f2e;
}

#chat .action-btns button {
  color: #fff;
  padding: 4px 6px;
  margin-bottom: 20px;
}

#chat .action-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#chat .action-btns button {
  display: none;
}

#chat .action-btns .showBtn {
  display: inline-block;
}

#chat .dispute-details {
  opacity: 0;
  display: none;
  padding: 10px;
  border-radius: 10px;
  color: #0c4f2e;
  z-index: 50;
  background-color: #fff;
  box-shadow: 3px 2px 12px 0px rgba(0, 0, 0, 0.27);
  transition: opacity 1s;
}

#chat .dispute-details.showing {
  display: block;
  position: absolute;
  width: calc(100% - 32px);
  left: 50%;
  transform: translateX(-50%);
  top: 55px;
  opacity: 1;
}

#chat .dispute-details p {
  font-weight: 700;
}

#chat .dispute-details .card-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

#chat .dispute-details .card-item > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#chat .dispute-details .card-item > div span:first-of-type {
  margin-bottom: 10px;
}

#chat .dispute-details .card-item:not(:last-of-type) {
  border-bottom: 0.0000005px solid #c0c0c0;
}

#chat .payment-ref {
  font-size: 24px;
  color: #0c4f2e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.chat-head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.chat-head .info {
  font-size: 16px;
  margin-left: auto;
}

.chat-head i {
  cursor: pointer;
}

.chat-head p {
  margin-left: 10px;
  color: #0c4f2e;
  font-weight: 800;
  font-size: 16px;
}

.arguments {
  font-size: 11px;
  flex: 1;
  margin-bottom: 8px;
  overflow: auto;
}

.arguments .argument {
  margin-bottom: 32px;
}

.arguments .argument .body {
  word-break: break-all;
  hyphens: auto;
}

.others {
  text-align: center;
}

.others .date {
  color: #84bc60;
  font-weight: 600;
}

.chat {
  display: flex;
  align-items: flex-start;
  color: #fff;
}

.chat.sent {
  flex-direction: row-reverse;
}

.chat img {
  width: 15px;
}

.chat .user-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 3px solid #aac1b6;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.chat .date {
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.chat .body {
  width: 60%;
  background-color: #1eae67;
  border-radius: 10px;
  padding: 10px;
  margin-left: 8px;
}

.chat.sent .body {
  position: relative;
  margin-left: 0;
  margin-right: 8px;
  background-color: rgba(73, 95, 120, 1);
}

.chat.sent .body i {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.message-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
  padding: 4px 10px;
  background-color: white;
  border: 1px solid #0c4f2e;
}

.message-box input {
  border: none;
  padding: 5px;
  background: transparent;
  border-radius: 0;
  width: 100%;
  color: #0c4f2e;
  font-size: 12px;
}

.message-box i {
  font-size: 12px;
}

.message-box > button {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  background-color: #0c4f2e;
  color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.pagination-container {
  text-align: center;
  color: #0c4f2e;
}

.pagination-container P {
  margin-bottom: 10px;
  font-weight: 600;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.pagination li {
  border: 1px solid #0c4f2e;
  padding: 3px 10px;
}

.pagination li.active {
  background-color: #0c4f2e;
  color: #fff;
}

.pagination li.previous,
.pagination li.next {
  border-radius: 6px;
  font-weight: 600;
}

.pagination li.disabled,
.pagination li.disabled a {
  opacity: 0.5;
  cursor: not-allowed;
}

.case-closed-msg {
  font-weight: 700;
  color: #0c4f2e;
  text-transform: uppercase;
  text-align: center;
}
